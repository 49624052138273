/* You can add global styles to this file, and also import other style files */
$primaryColor: #007bff;
$secondaryColor: #6c757d;
$successColor: #198754;
$helpColor: #6f42c1;
$infoColor: #17a2b8;
$warnColor: #FFC106;
$dangerColor: #dc3545;

body {
  margin: 0px;
}
.label {
  font-size: 16px;
  margin: 4px;
  margin-bottom: 5px;
  font-weight: 600;
}
 .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 68% !important;
  height: 68% !important;
  transition-duration: 0.15s;
  background-color: #ffffff;
  /* padding-left: 11px; */
  letter-spacing: 1px;
}
.file {
  background-color: #eaecef;
  width: 80%;
  padding: 8px 32px;
  background-color: #eaecef5c;
  margin: 8px;
  text-align: left;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

::host :ng-deep .bg-red-900 {
  background-color: red !important;
}

.cursor {
  cursor: pointer;
}

.required {
  color: red;
}

.p-dialog-header {

}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  color: #555;
  justify-content: center !important;
  text-align: center !important;
      width: 97%;
}

.cursor-pointer {
  cursor: pointer;
}
.submission-board {
  .p-panel-header {
    background-color: $primaryColor !important;
    color: var(--blue-50) !important;
    border: 0px solid #ffffff !important;
  }
  .p-panel-content {
    padding: 2px !important;
    background-color: #ffffff !important;
    border: 0px solid #ffffff !important;
  }
}

.additional-board {
  .p-panel-header {
    background-color: $dangerColor !important;
    color: var(--yellow-50) !important;
    border: 0px solid #ffffff !important;
    font-weight: 600 !important;
  }
  .p-panel-content {
    padding: 0.5rem !important;
    background-color: #ffffff !important;
    border: 0px solid #ffffff !important;
  }
}

.policy-board {
  .p-panel-header {
    background-color: $warnColor !important;
    color: var(--green-50) !important;
    border: 0px solid #ffffff !important;
    font-weight: 600 !important;
  }
  .p-panel-content {
    padding: 0.5rem !important;
    background-color: #ffffff !important;
    border: 0px solid #ffffff !important;
  }
}

.underwriting-board {
  .p-panel-header {
    background-color: #84618C !important;
    color: var(--purple-50) !important;
    border: 0px solid #ffffff !important;
    font-weight: 600 !important;
  }
  .p-panel-content {
    padding: 0.5rem !important;
    background-color: #ffffff !important;
    border: 0px solid #ffffff !important;
  }
}

.verification-board {
  .p-panel-header {
    background-color: $successColor !important;
    color: var(--green-50) !important;
    border: 0px solid #ffffff !important;
    font-weight: 600 !important;
  }
  .p-panel-content {
    padding: 0.5rem !important;
    background-color: #ffffff !important;
    border: 0px solid #ffffff !important;
  }
}
.panel-container {
  min-height: 70px;
}

.p-menuitem-link-active {
  span {
    font-weight: bold;
    color: $primaryColor !important;
  }
}
.filter-holder {
  p-dropdown {
    .p-dropdown {
      width: 100%;
    }
  }
  .p-calendar {
    width: 100% !important;
  }
}

.label {
  font-size: 16px;
  margin: 4px;
  margin-bottom: 5px;
  font-weight: 600;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #80808024 !important;
  padding: 0 !important;
}
/* width for scroll bar styling */
::-webkit-scrollbar {
  // width: 4px;
}

/* Track scroll bar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.d-flex {
  display: flex;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.custom-card {
  padding: 0.6rem;
  background: #ffffff;
  color: #212529;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.w-100 {
  width: 100%;
}

.nbc-card{
  background: white !important;
  border-radius: 8px;
}
.f12{
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.medical-history-bg {
  background: #22466C 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 15px;
}
.demographic-bg {
  background: #22466C 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 15px;
}
.gray-color {
  color: #9EA3A8;
}
.nbc-header {
  color: #262626 !important;
  font-size: 1.15rem !important;
  font-weight: 600 !important;
}
.main-header {
  font-size: 1.25rem !important;
  color: #262626 !important;
}
.progressbar-label {
  color: #262626;
  font-weight: 600 !important;
  font-size: 1rem !important;
}
.text-gray-color{
  color: #707070;
}
.p-inputtext {
  font-family: Roboto !important;
}